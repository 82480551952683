import { Alert, Box, Button, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import DataService from '../../../services/DataService';
import { ProjectDto, TaskDto } from '../../../types';
import LoanService from '../../../services/LoanService';

interface CheckChangesTaskProps {
    isMobile: boolean;
    task: TaskDto;
}

declare global {
}

export const SignLoanContractTask: React.FC<CheckChangesTaskProps> = (props) => {
    const [project, setProject] = useState<ProjectDto | null>(null);
    const [riskInfoChecked, setRiskInfoChecked] = useState(false);
    const [contractDownloaded, setContractDownloaded] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        }
    }, []);

    useEffect(() => {
        const loanId = props.task.variables.loanId as string;
        if (loanId) {
            LoanService.getProjectForLoan(loanId).then((project: ProjectDto) => {
                setProject(project);
            }).catch((error) => {
                console.error('Error loading project:', error);
            });
        }
    }, [props.task]);

    return (
        <Box
            className='content'
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            padding={2}
        >
            <Box
                className='content'
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                padding={3}
                boxShadow={3}
                borderRadius={2}
                border={1}
                borderColor='grey.300'
                bgcolor='background.paper'
                maxWidth={600}
                width='100%'
            >
                <Typography variant='h5' gutterBottom>
                    Darlehensvertrag abschließen
                </Typography>

                <Typography paragraph>
                    Bitte laden Sie den generierten Darlehensvertrag herunter und kontrollieren Sie die darin enthaltenen Daten auf ihre Richtigkeit.
                    Eine Unterschrift ist für den Vertragsabschluss nicht notwendig.
                    Durch das Setzen der Häkchen und den Klick auf den Button "Darlehensvertrag rechtskräftig abschließen" wird der Darlehensvertrag rechtsverbindlich abgeschlossen.
                    Anschließend werden Sie per Mail dazu aufgefordert die entsprechende Darlehenssumme an unser Konto zu überweisen.
                    Vielen Dank für Ihre Unterstützung!
                </Typography>

                <Button
                    variant='contained'
                    onClick={() => { setContractDownloaded(true);
                        DataService.downloadAttachment(
                        props.task.variables['loanContractFileRef'],
                        props.task.variables['loanContractFileName'],
                    )}}
                    sx={{ marginBottom: 2 }}
                >
                    {props.task.variables['loanContractFileName']} Herunterladen
                </Button>

                <Typography sx={{mb: 3}}>
                    Sie finden den Darlehensvertrag auch unter <Link href='/attachments'>Dokumente</Link>.
                </Typography>

                {project?.riskInformationDocumentFileRef && (
                    <FormControlLabel
                        control={<Checkbox checked={riskInfoChecked} onChange={(e) => setRiskInfoChecked(e.target.checked)} />}
                        label={
                            <Typography>
                                Ich nehme die <a href='#' onClick={() => DataService.downloadAttachment(
                                project.riskInformationDocumentFileRef,
                                project.riskInformationDocumentFileRef.split('?name=')[1].replace('.pdf', ''),
                            )}>Risikoinformationen</a> zur Kenntnis.
                            </Typography>
                        }
                    />
                )}

                {project?.loanConditionsDocumentFileRef && (
                    <FormControlLabel
                        control={<Checkbox checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />}
                        label={
                            <Typography>
                                Ich bin mit der Geltung der <a href='#' onClick={() => DataService.downloadAttachment(
                                project.loanConditionsDocumentFileRef,
                                project.loanConditionsDocumentFileRef.split('?name=')[1].replace('.pdf', ''),
                            )}>Zeichnungsbedingungen</a> einverstanden.
                            </Typography>
                        }
                        sx={{ marginBottom: 2 }}
                    />
                )}

                <Alert severity="info" sx={{mb: 2}}>
                    Wenn Sie mit dem Vertrag einverstanden sind, dann klicken Sie bitte auf den Button "Darlehensvertrag rechtskräftig abschließen". Andernfalls kontaktieren Sie uns bitte per Mail!
                </Alert>

                <Stack spacing={2} direction='column' width='100%'>
                    <Button
                        disabled={!riskInfoChecked || !termsChecked || !contractDownloaded}
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                            await DataService.signContract(props.task.id);
                            navigate('/projectloans');
                        }}
                    >
                        Darlehensvertrag zahlungspflichtig abschließen
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
