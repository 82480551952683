import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { TaskDto } from '../../../types';
import TaskService from '../../../services/TaskService';

interface TaskProps {
    isMobile: boolean;
    task: TaskDto;
}

declare global {
}

export const CheckChangesTask: React.FC<TaskProps> = (props) => {
    const navigate = useNavigate();

    useEffect(() => {

        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        }
    }, []);

    return (
        <Box
            display='flex'
            justifyContent='center'>
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                padding={4}
                margin={2}
                boxShadow={3}
                borderRadius={2}
                bgcolor='background.paper'
                maxWidth={500}
                width='100%'
            >
                <Typography
                    variant='h6'
                    gutterBottom
                    align='center'
                    color='primary'
                >
                    Änderungen wurden angefragt!
                </Typography>
                <Typography
                    variant='body1'
                    align='center'
                    paragraph
                >
                    Der Genossenschaftskoordinator hat Änderungen für das Darlehen angefragt!
                </Typography>
                <Typography
                    variant='body2'
                    align='center'
                    paragraph
                    fontStyle='italic'
                >
                    Begründung: "{props.task.variables.note}"
                </Typography>
                <Typography
                    variant='body2'
                    align='center'
                    paragraph
                    color='text.secondary'
                >
                    Vorschlag für einen neuen Darlehenswert: <strong>{props.task.variables.newValue}€</strong>
                </Typography>
                <Alert severity='info' sx={{ my: 2 }}>Der tatsächliche Vertragsabschluss erfolgt erst nach erneuter Freigabe
                    durch die Vorstandschaft und einer weiteren Zustimmung Ihrerseits.</Alert>
                <Stack spacing={2} direction='row' mt={2}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={async () => {
                            await TaskService.complete(props.task.id, { decisionOnChangeRequest: 'approved' });
                            navigate('/projectloans');
                        }}
                    >
                        Annehmen
                    </Button>
                    <Button
                        variant='contained'
                        color='error'
                        size='large'
                        onClick={async () => {
                            await TaskService.complete(props.task.id, { decisionOnChangeRequest: 'declined' });
                            navigate('/projectloans');
                        }}
                    >
                        Ablehnen
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
